import { useState, createContext, useContext, useEffect } from "react";
import { getLocation } from "./services/getLocation";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebase";

// Context created here
const Context = createContext({});

// Context provider created below
export const ContextProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState(false);
  const [userData, setUserData] = useState({});
  const [posLong, setPosLong] = useState("");
  const [posLat, setPosLat] = useState("");

  const getLatLong = () => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        setPosLat(res.coords.latitude);
        setPosLong(res.coords.longitude);

        getLocation(res.coords.latitude, res.coords.longitude);
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    getLatLong();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        sessionStorage.setItem("userData", JSON.stringify(user));

        setUserData(user);
        setUserAuth(true);
      }
    });
  }, []);

  return (
    <Context.Provider value={{ userAuth, userData, posLat, posLong }}>
      {children}
    </Context.Provider>
  );
};

// Custom hook to read context data
export const ReadContext = () => {
  const { userAuth } = useContext(Context);
  const { userData } = useContext(Context);
  const { posLong } = useContext(Context);
  const { posLat } = useContext(Context);

  const value = {
    userAuth,
    userData,
    posLong,
    posLat,
  };

  return value;
};
