import {
  Divider,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import React from "react";
import style from "./style.module.css";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Close, Person } from "@mui/icons-material";
import { userLogout } from "../firebase";


export default function MobileFooter({ yellow, blue }) {
  const [active, setActive] = useState(false);
 
  return (
    <>
      <div className={style.footer}>
        <Grid
          container
          py={2}
          px={2}
          sx={{
            // marginTop:"400px",
            display: "flex",
            justifyContent: "space-between",
            position: "fixed",
            bottom: "0",
            background: "white",
            boxShadow: "0 0 5px 0 #888888",
            zIndex: "2222",
          }}
        >
          <Grid item>
            {/* {active ? (
              <Close
                onClick={() => setActive(!active)}
                sx={{ fontSize: "30px" ,}}
              />
            ) : (
              <SettingsIcon
                onClick={() => setActive(!active)}
                sx={{ fontSize: "30px" }}
              />
            )} */}
            <IconButton onClick={() => setActive(!active)}>
              {active ? <Close  /> : <SettingsIcon />}
            </IconButton>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Link to="/consumer/need-help">
                  <PersonIcon sx={{ fontSize: "30px", color: yellow }} />
                </Link>
              </Grid>
              <Grid item>
                <SyncAltIcon sx={{ fontSize: "30px" }} color="info" />
              </Grid>
              <Grid item>
                <Link to="/contractor/open-service">
                  <PersonIcon sx={{ fontSize: "30px", color: blue }} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <LogoutIcon
              sx={{ fontSize: "30px" }}
              onClick={() => userLogout()}
            />
          </Grid>
        </Grid>
      </div>
      <Drawer
        sx={{
          position: "relative",
          width: "225px",

          "& .MuiDrawer-paper": {
            backgroundColor: "#fcfcfc",
            width: "225px",
            border: 0,
          },
        }}
        open={active}
        variant="persistent"
        anchor="left"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
        <List>
          <Link to="/about">
            <ListItemButton>
              <ListItemText primary="About" />
            </ListItemButton>
          </Link>
          <Link to="/faq">
            <ListItemButton>
              <ListItemText primary="FAQ" />
            </ListItemButton>
          </Link>
          <Link to="/terms">
            <ListItemButton>
              <ListItemText primary="Terms" />
            </ListItemButton>
          </Link>
          <Link to="/contact">
            <ListItemButton>
              <ListItemText primary="Contact" />
            </ListItemButton>
          </Link>
          <Link to="/privacy">
            <ListItemButton>
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </Link>
        </List>
      </Drawer>
    </>
  );
}
