import { Box, Container, Typography, Grid } from "@mui/material";

export const Receipt = () => {
  return (
    <>
      <Container>
        <Box boxShadow={4} p={4} marginY={4} borderRadius={4}>
          <Typography variant="h5">
            <b>Thank You For Your Order!</b>
          </Typography>
          <br />
          <hr />
          <br />
          <Typography bgcolor="gray" p={1} color="white">
            <b>Order Information</b>
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Merchant
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              Himanshu Masant
            </Grid>
            <Grid item xs={4}>
              Description
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
              alias vitae tempore est id atque, culpa animi omnis, amet repellat
              ducimus velit quod ullam commodi vel recusandae nam asperiores
              perspiciatis!
            </Grid>
            <Grid item xs={4}>
              Date/Time
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              15 May 2023 23:00:00
            </Grid>
            <Grid item xs={4}>
              Customer Id
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              SDFJKL234567
            </Grid>
          </Grid>
          <br />
          <hr />
          <br />
          <Grid container spacing={2}>
            <Grid item md={2}>
              <b>Item</b>
            </Grid>
            <Grid item md={2}>
              <b>Description</b>
            </Grid>
            <Grid item md={2}>
              <b>Qty</b>
            </Grid>
            <Grid item md={2}>
              <b>Taxable</b>
            </Grid>
            <Grid item md={2}>
              <b>Unit Price</b>
            </Grid>
            <Grid item md={2}>
              <b>Item Total</b>
            </Grid>
            <Grid item md={2}>
              Super Service
            </Grid>
            <Grid item md={2}>
              Super Service
            </Grid>
            <Grid item md={2}>
              1
            </Grid>
            <Grid item md={2}>
              N
            </Grid>
            <Grid item md={2}>
              $ 5.00 (USD)
            </Grid>
            <Grid item md={2}>
              $ 5.00 (USD)
            </Grid>
          </Grid>
          <br />
          <hr />
          <br />
          <Typography textAlign="right">
            <b>Total : $5.00 (USD)</b>
          </Typography>
          <br />
          <Typography bgcolor="gray" p={1} color="white">
            <b>Card Details</b>
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Date/Time
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              15 May 2023 23:00:00
            </Grid>
            <Grid item xs={4}>
              Transaction ID
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              401123780
            </Grid>
            <Grid item xs={4}>
              Auth Code
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              401123780
            </Grid>
            <Grid item xs={4}>
              Payment Method
            </Grid>
            <Grid item xs={2}>
              <b>:</b>
            </Grid>
            <Grid item xs={6}>
              Visa ****2468
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
